.logo{
    height: 3rem;
}

i {
    cursor: pointer;
}

.product{
    height: 35rem;
   
}

.product-title{
    color:blue;
    max-width: 15rem;
}

.product-img{
    height: 20rem;
}

